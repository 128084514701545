import {
  Box, ChakraProvider, Icon, Text, VStack, HStack, Link, Stack
} from "@chakra-ui/react"
import { FaGithub, FaLinkedinIn, FaRegFilePdf, FaTwitter } from "react-icons/fa"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import Brand from "./components/Brand"
import theme from './theme'
import Cv from "./components/Cv"
import cvData from './data/cv.json';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box>
      <ColorModeSwitcher justifySelf="flex-end" position={'absolute'} top={5} right={5} />
      <Stack minH="100vh" p={3} justify={'center'} align={'center'} spacing={10} direction={['column', 'row']}>
        <Box p={10} textAlign={'center'}>
          <VStack spacing={3}>
            <Brand boxSize={20} />
            <Text fontSize={'3xl'}>
              Frédéric KOLLER
            </Text>
          </VStack>
          <VStack spacing={5}>
            <Text fontSize={'md'} color={'brandRed'}>
              développeur Full Stack
            </Text>
            <HStack>
              <Link href="//www.linkedin.com/in/frederic-koller/" isExternal>
                <Icon as={FaLinkedinIn} />
              </Link>
              <Link href="//github.com/fredckl" isExternal>
                <Icon as={FaGithub} />
              </Link>
              <Link href="https://twitter.com/rowrules" isExternal>
                <Icon as={FaTwitter}/>
              </Link>
              <Link href="https://drive.google.com/file/d/1WZNR7rB9Qe5_rrkYADW_xQDs1sMhcVN9/view?usp=sharing" isExternal>
                <Icon as={FaRegFilePdf}/>
              </Link>
            </HStack>
          </VStack>
        </Box>
        <Box p={10} h={290} overflow={'scroll'} maxW={350} className="cv-list">
          <Cv jobs={cvData.data}/>
        </Box>
      </Stack>
    </Box>
  </ChakraProvider >
)
