import { styles } from './styles'
import { fonts } from './fonts'
import { colors } from './colors'
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const theme = extendTheme( {
  fonts,
  styles,
  colors
},
withDefaultColorScheme({
  colorScheme: 'brandRed'
})
)

export default theme