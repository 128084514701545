import { toggleStyle } from './helpers';

export const styles = {
  global: (props: any) => {
    const ts = toggleStyle(props);

    return {
      body: {
        fontFamily: '"Nunito", sans-serif',
        color: ts('rgb(33, 37, 41)', '#f8fafc'),
        backgroundColor: ts('#f8fafc' , 'rgb(26, 32, 44)')
      }
    }
  }
}