import { Text } from '@chakra-ui/react'
import { compose, isNil, join, reject } from 'ramda'
import React from 'react'
import { dateEnToFr } from '../helpers/dates'

const Dates: React.FunctionComponent<{ startedAt: string, finishedAt: string}> = ({ startedAt, finishedAt }) => {
  const dates = compose(
    join(' - '),
    // @ts-ignore
    reject(isNil)
  )([
    dateEnToFr(startedAt),
    finishedAt ? dateEnToFr(finishedAt) : 'à ce jour'
  ])
  return (
    <Text textColor={'gray'} fontStyle={'italic'} fontSize={'xs'}>
      {dates}
    </Text>
  )
}

export default Dates