import { Box, List, ListItem, Text } from '@chakra-ui/react';
import { path, prop } from 'ramda';
import React from 'react';
import Dates from './Dates';


type DataProps = {
  startedAt: string;
  finishedAt: string;
  company: {
    name: string;
    link: string;
    decription: string;
  };
  job: {
    title: string;
    techno: string[];
    description: string;
  };
}


const Cv: React.FunctionComponent<{ jobs: DataProps[] }> = ({jobs}) => {
  return (
    <div>
      <List borderRightWidth={1} borderRightStyle={'solid'} borderRightColor={'grey'} pr={5} py={5}>
        {jobs.map((job: any) => (
          <ListItem key={job.id}>

            <Box mb={10} textAlign={'right'} position={'relative'}>
              <Box
                position={'absolute'}
                w={'11px'}
                height={'11px'}
                rounded={'full'}
                borderWidth={'1px'}
                borderStyle={'solid'}
                borderColor={'brandGreyLight'}
                right={'-26px'}
                top={'4px'}
                bg={'brandGreyDark'}
              />
              <Dates startedAt={prop('startedAt', job)} finishedAt={prop('finishedAt', job)}/>
              <Text fontSize={'xl'}>- {path(['company', 'name'], job)} -</Text>
              <Text textColor={'gray'} fontSize={'sm'}>{path(['job', 'title'], job)}</Text>
              <Text pt={3} fontSize={'sm'}>{path(['job', 'description'], job)}</Text>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default Cv